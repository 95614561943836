<template>
<div>
  <PortailOffreExterneList v-if="typeOffre == 'offreExterne'">
    <template #offreSwitcher>
      <!-- <PSelectButton class="" v-model="typeOffre" :options="options" :optionLabel="'label'" :optionValue="'value'" /> -->
      <PTabMenu class="p-highlight" :model="options" :activeIndex.sync="activeIndex" />
    </template>
  </PortailOffreExterneList>
  <PortailOffreList v-else>
    <template #offreSwitcher>
      <!-- <PSelectButton v-model="typeOffre" :options="options" :optionLabel="'label'" :optionValue="'value'" /> -->
      <PTabMenu class="p-highlight" :model="options" :activeIndex.sync="activeIndex" />
    </template>
  </PortailOffreList>
</div>
</template>

<script>
import PortailOffreList from './PortailOffreList.vue'
import PortailOffreExterneList from './PortailOffreExterneList.vue'
import { mapActions } from 'vuex'

export default {
    components: {
      PortailOffreList,
      PortailOffreExterneList
    },
    data(){
      return {
        activeIndex: 0,
        offreStat: {
          offresCount: 0,
          offresAnpeCount: 0,
          offresExterneCount: 1,
        },
        // typeOffre:  this.$route.params.typeOffre == 'offreExterne'? 'offreExterne' :  'offreAnpe' ,

      }
    },
    created(){
      this.activeIndex = this.$route.query.typeOffre == 'offreExterne'? 1 :  0
      this.fetchOffresCount().then((data) => {
        this.offreStat = data
      })
    },
    computed: {
      typeOffre(){
        return  this.activeIndex == 0? 'offreAnpe' : 'offreExterne'
      },
      options(){
        return [
          { value: 'offreAnpe', label: `Offres gérées par l\'ANPE - ${this.offreStat.offresAnpeCount}`},
          { value: 'offreExterne', label: `Autres offres - ${this.offreStat.offresExterneCount}`},
        ]
      }
    },
    methods: {
      ...mapActions({
        fetchOffresCount: "offre/fetchOffresCount"
      })
    }
};
</script>